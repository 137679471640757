import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import { Button, Col, Row } from 'DesignSystem'

import useCompany from 'Hooks/API/useCompany.hook'
import useIsFirstAccess from 'Hooks/useIsFirstAccess.hook'
import useNeoDashLinks from 'Hooks/useNeoDashLinks'

function Finish ({
  prev,
  setSizeDialog,
  setShowFooter,
  dataContentManager,
  next
}: any) {
  const intl = useIntl()
  const history = useHistory()
  const { isFirstAccess } = useIsFirstAccess()
  const { id: companyId } = useParams<any>()
  const { data: company } = useCompany({ id: companyId })
  const { generateLink } = useNeoDashLinks({ companyId })
  useEffect(() => {
    setSizeDialog('sm')
    setShowFooter(false)
  }, [])

  return (
    <Row className="flex-column">
      <Col className="text-center mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Conexão configurada!' })}</h2>
        <p className='text-start body2'>
          {dataContentManager.hasInnerAccount ? (
            intl.formatMessage({ id: 'Neste momento, estamos importando os dados de mídia das campanhas disponíveis nas contas de anúncio selecionadas. Dentro de alguns instantes, esses dados já estarão disponíveis em seu workspace.' })
          ) : (
            intl.formatMessage({ id: 'Você já pode utilizar sua nova conexão em seus grupos de dados e importar os dados de mídia disponíveis nela para o NeoDash.' })
          )}
        </p>
      </Col>
      <Col className="mt-5 text-center">
        <Button color="white" onClick={() => {
          next()
          history.replace({
            search: '?new-connection=true'
          })
        }}>
          {intl.formatMessage({ id: 'Adicionar nova conexão' })}
        </Button>
        {isFirstAccess && companyId ? (
          <Button onClick={() => company && window.open(`${generateLink('campanha-master/1')}?is_first_access=true`, '_blank')} className="ms-3">
            {intl.formatMessage({ id: 'Acessar meu dashboard' })}
          </Button>
        ) : (
          <Button onClick={next} className="ms-3">
            {intl.formatMessage({ id: 'Concluir' })}
          </Button>
        )}
      </Col>
    </Row>
  )
}

export default Finish
