
import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
// import { useHistory, useLocation } from 'react-router-dom'

import { Button, Input, Row } from 'DesignSystem'
import type formatMessageInterface from 'Interfaces/formatmessage.interface'
import AuthLayout from 'Layouts/AuthLayout'
import Content from 'Layouts/AuthLayout/Components/Content'
import { isEmpty } from 'lodash'

import { addAlert } from 'Store/Actions/alerts.action'
import { recoverPassword } from 'Store/Actions/User/password.action'

import { generateLinkTo } from 'Helpers/Route.helper'

function ForgotPassword (props:formatMessageInterface) {
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [executed, setExecuted] = useState<boolean>(false)
  const intl = useIntl()
  const dispatch = useDispatch()
  // const history = useHistory()
  // const location = useLocation()

  function clearState () {
    setEmail('')
    setLoading(false)
  }

  // function redirectPage () {
  //   history.push(`/login/${location.search}`)
  // }

  function validFields () {
    if (!isEmpty(email)) {
      return true
    } else {
      dispatch(
        addAlert({ title: 'Preencha o campo E-mail!', type: 'error' })
      )
    }
  }

  async function requestAPI () {
    setLoading(true)

    try {
      const resAction = await recoverPassword(email)
      dispatch(resAction)
      setLoading(false)

      if (resAction?.payload?.type !== 'error') {
        setExecuted(true)
        dispatch(
          addAlert({
            title: intl.formatMessage({ id: 'Encontramos sua conta.' }),
            content: intl.formatMessage({ id: 'Enviamos um email, para prosseguir com a recuperação de senha.' }),
            type: 'success'
          })
        )
        // redirectPage()
      }
    } catch (e:any) {
      setLoading(false)
      dispatch(
        addAlert({ title: e.message, type: 'error' })
      )
    }
  }

  async function findAccount () {
    if (validFields()) {
      await requestAPI()
    }
  }

  useEffect(() => {
    return () => {
      clearState()
    }
  }, [])

  const contentForm = (
    <form noValidate autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
      <p>{intl.formatMessage({ id: 'Insira o e-mail utilizado para acessar seu NeoDash:' })}</p>
      <Input
        className="mt-3"
        placeholder={intl.formatMessage({ id: 'Digite aqui seu e-mail' })}
        value={email}
        onChange={(event: any) => setEmail(event?.target?.value)}
      />
      <Row className="flex-row mt-3 gap-4">
        <Button className='flex-grow-1' color="gray" href={generateLinkTo('login')}>
          <FormattedMessage id="Voltar"/>
        </Button>
        <Button isLoading={loading} className='flex-grow-1' color="primary" onClick={() => findAccount()}>
          {intl.formatMessage({ id: 'Continuar' })}
        </Button>
      </Row>
    </form>
  )

  const contentReady = (
    <div>
      <form noValidate autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        <p className='text-center'>{intl.formatMessage({ id: 'Caso o e-mail informado esteja cadastrado, você receberá um link nele para redefinir sua senha.' })}</p>
      </form>
    </div>
  )

  return (
    <AuthLayout>
      <Content title={executed ? intl.formatMessage({ id: 'Quase lá...' }) : intl.formatMessage({ id: 'Redefina a sua senha' })}>
        {executed ? contentReady : contentForm}
      </Content>
    </AuthLayout>
  )
}

export default injectIntl(ForgotPassword)
