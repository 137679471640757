import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Archiveds from 'Pages/Privates/Archiveds'
import Companies from 'Pages/Privates/Companies'
import CompanyAccounts from 'Pages/Privates/Company/companyAccount.page'
import CompanyAccountEdit from 'Pages/Privates/Company/companyAccountEdit.page'
import CompanyAccountLog from 'Pages/Privates/Company/companyAccountLog'
import CompanyAccountLogs from 'Pages/Privates/Company/companyAccountLogs.page'
import CompanyAccountJobs from 'Pages/Privates/Company/companyJobs.page'
import CompanyLinkTemporary from 'Pages/Privates/Company/companyLinksTemporary.page'
import CompanySuccessful from 'Pages/Privates/Company/companySuccessful.page'
import NewCreateCompany from 'Pages/Privates/Company/newCreate.page'
import CompanyEdit from 'Pages/Privates/CompanyEdit'
import Connections from 'Pages/Privates/Connections'
import ConnectionsShow from 'Pages/Privates/ConnectionsShow'
import Logs from 'Pages/Privates/Logs'
import NotFound from 'Pages/Privates/NotFound/NotFound.page'
import Odash from 'Pages/Privates/Odash'
import Routines from 'Pages/Privates/Routines'
import Subscription from 'Pages/Privates/Subscription'
import SuperAdmin from 'Pages/Privates/SuperAdmin'
import LoginOdash from 'Pages/Publics/LoginOdash'
import Signup from 'Pages/Publics/Signup/Signup.page'
import LoginFromExternal from 'Pages/Privates/LoginFromExternal'
import { ThemeProvider } from 'styled-components'
import theme from 'Theme'

import Container from 'Components/Container'

import { getPath } from 'Helpers/Route.helper'

function PrivatesRoutes () {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Switch>
          <Route path={getPath('companyAccounts')}>
            <CompanyAccounts />
          </Route>

          <Route path={getPath('companyAccountEdit')}>
            <CompanyAccountEdit />
          </Route>

          <Route path={getPath('linkTemporary')}>
            <CompanyLinkTemporary />
          </Route>

          <Route path={getPath('logsByJobs')}>
            <CompanyAccountLogs />
          </Route>

          <Route path={getPath('logsByCompany')}>
            <CompanyAccountLogs />
          </Route>

          <Route path={getPath('log')}>
            <CompanyAccountLog />
          </Route>

          <Route path={getPath('jobsByType')}>
            <CompanyAccountJobs />
          </Route>

          <Route path={getPath('companyConnectionEdit')}>
            <CompanyEdit />
          </Route>

          <Route path={getPath('companyEditStepNew')}>
            <CompanyEdit />
          </Route>

          <Route path={getPath('companyEditStep')}>
            <CompanyEdit />
          </Route>

          <Route path={getPath('companyEdit')}>
            <CompanyEdit />
          </Route>

          <Route path={getPath('jobs')}>
            <CompanyAccountJobs />
          </Route>

          <Route path={getPath('companyCreateStep')}>
            <NewCreateCompany />
          </Route>

          <Route path={getPath('companies')}>
            <Companies />
          </Route>

          <Route path={getPath('connectionsShow')}>
            <ConnectionsShow />
          </Route>

          <Route path={getPath('connections')}>
            <Connections />
          </Route>

          <Route path={getPath('connectionNewCompany')}>
            <Connections />
          </Route>

          <Route path={getPath('connectionNew')}>
            <Connections />
          </Route>

          <Route path={getPath('connectionEditCompanyNewConnection')}>
            <Connections />
          </Route>

          <Route path={getPath('connectionEditCompany')}>
            <Connections />
          </Route>

          <Route path={getPath('connectionEditNewConnection')}>
            <Connections />
          </Route>

          <Route path={getPath('connectionEdit')}>
            <Connections />
          </Route>

          <Route path={getPath('routines')}>
            <Routines />
          </Route>

          <Route path={getPath('logs')}>
            <Logs />
          </Route>

          <Route path={getPath('signupWithInvite')}>
            <Signup />
          </Route>

          <Route path={getPath('odashLogin')}>
            <LoginOdash />
          </Route>

          <Route path={getPath('odashForgotPassword')}>
            <LoginOdash />
          </Route>

          <Route path={getPath('odashInvite')}>
            <LoginOdash />
          </Route>

          <Route path={getPath('odash')}>
            <Odash />
          </Route>

          <Route path={getPath('odashAdmin')}>
            <Odash />
          </Route>

          <Route path={getPath('superAdmin')}>
            <SuperAdmin />
          </Route>

          <Route path={getPath('home')}>
            <Companies />

            <Route path={getPath('companySuccessful')}>
              <CompanySuccessful />
            </Route>
          </Route>

          <Route path={getPath('archiveds')}>
            <Archiveds />
          </Route>

          <Route path={getPath('subscription')}>
            <Subscription />
          </Route>

          <Route path={getPath('notFound')}>
            <NotFound />
          </Route>

          <Route path={getPath('loginFromExternal')}>
            <LoginFromExternal />
          </Route>

          <Redirect from="/" to="/home" exact />
          <Redirect from="/token" to="/home" />
          <Redirect from="/login" to="/home" />
          <Redirect from="/activate/:activationCode" to="/home" />

          <Redirect from="*" to="/not-found" />
        </Switch>
      </Container>
    </ThemeProvider>
  )
}

export default PrivatesRoutes
