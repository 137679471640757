import { CheckCircle } from '@phosphor-icons/react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import LoadingDots from 'DesignSystem/LoadingDots'
import AuthLayout from 'Layouts/AuthLayout'
import Content from 'Layouts/AuthLayout/Components/Content'

import { getPath } from 'Helpers/Route.helper'

function WaitingEmailConfirmation () {
  const intl = useIntl()
  return (
    <AuthLayout>
      <Content title={intl.formatMessage({ id: 'Cadastre-se para usar o NeoDash' })}>
        <div className='d-flex'>
          <div>
            <CheckCircle weight='fill' size={24} color='#24813B' />
          </div>
          <p className='text-start ms-2'>
            {intl.formatMessage({ id: 'Seu cadastro foi feito e agora você precisa ativá-lo. Para fazer isso, basta clicar no link do e-mail que enviamos para sua caixa de entrada.' })}
          </p>
        </div>
        <div className='my-5'>
          <LoadingDots />
        </div>
        <div className="mt-3">
          <p className="text-center body2">
            {intl.formatMessage({ id: 'Já possui uma conta?' })}{' '}
            <b><Link to={getPath('login')}>{intl.formatMessage({ id: 'Faça login no Neodash' })}</Link></b>
          </p>
        </div>
      </Content>
    </AuthLayout>
  )
}

export default WaitingEmailConfirmation
