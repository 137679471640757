import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { Button, Col, Input, Row, SelectAdvanced } from 'DesignSystem'
import type DefaultProps from 'DesignSystem/Types'
import styled from 'styled-components'

import type { PlatformI } from 'Config/plataform.conf'
import platforms from 'Config/plataform.conf'

import type { IconSVGPropsI } from 'Components/IconSVG'
import IconSVG from 'Components/IconSVG'

import useCheckPermission from 'Hooks/API/useCheckPermission.hook'
import useCompanies from 'Hooks/API/useCompanies.hook'

import { getPath } from 'Helpers/Route.helper'

interface TaggedPlatformI {
  tag: string
  name: string
  platforms: PlatformI[]
}

interface CardPlatformI extends DefaultProps {
  isSelected: boolean
  isDisabled: boolean
}

const CardPlatform = styled.div<CardPlatformI>`
  border: 1px solid ${({ theme, isSelected }) => isSelected ? theme.colors.primary : theme.colors.gray};
  border-radius: 10px;

  background-color: ${({ theme, isDisabled, isSelected }) => {
    if (isDisabled) {
      return theme.colors.gray60
    }

    if (isSelected) {
      return theme.colors.primary_light_hover
    }

    return theme.colors.white
  }};


  &:hover {
    border-color: ${({ theme, isDisabled }) => theme.colors.primary};
  }

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  cursor: pointer;

  padding: ${({ theme }) => theme.metrics.space4};

  opacity: ${({ isDisabled }) => isDisabled ? 0.75 : 1};
  span, svg {
    width: 3rem !important;
    height: 3rem !important;
  }
`

function SelectPlatform ({
  prev,
  setSizeDialog,
  dataContentManager,
  setShowFooter,
  setData
}: any) {
  const intl = useIntl()
  const { id: companyIdParam } = useParams<any>()
  const [searchPlatform, setSearchPlatform] = useState('')
  const { data: companies } = useCompanies()
  const { data: dataCheckPermission } = useCheckPermission(platforms.map(p => p.permission), dataContentManager.companies)

  const here = useMemo(() => {
    const { companies: companiesSelecteds } = dataContentManager
    let path
    if (!companyIdParam && (companiesSelecteds && companiesSelecteds.length > 0)) {
      const [companyId] = companiesSelecteds
      path = getPath('connections') + `?company_id=${companyId}&edit=:account_id`
    } else {
      path = getPath('companyEditStep').replace(':id', companyIdParam).replace(':step', 'connections')
      path = `${path}?edit=:account_id`
    }
    const params = new URLSearchParams(window.location.search)
    params.delete('new-connection')
    return process.env.REACT_APP_FRONT_URL + path + '&' + params.toString()
  }, [companyIdParam, dataContentManager])
  console.log(here)

  useEffect(() => {
    setSizeDialog('lg')
    setShowFooter(true)
  }, [])

  useEffect(() => {
    if (companyIdParam) {
      setData({ companies: [companyIdParam] })
    }
  }, [])

  const taggedsPlatforms: TaggedPlatformI[] = [
    {
      tag: 'more_used',
      name: intl.formatMessage({ id: 'Mais utilizados' }),
      platforms: []
    },
    {
      tag: 'search',
      name: intl.formatMessage({ id: 'Pesquisa' }),
      platforms: []
    },
    {
      tag: 'tracking',
      name: intl.formatMessage({ id: 'Tracking' }),
      platforms: []
    },
    {
      tag: 'display_remarketing',
      name: intl.formatMessage({ id: 'Display Remarketing' }),
      platforms: []
    },
    {
      tag: 'social',
      name: intl.formatMessage({ id: 'Social' }),
      platforms: []
    },
    {
      tag: 'ecommerce',
      name: intl.formatMessage({ id: 'E-commerce' }),
      platforms: []
    },
    {
      tag: 'crm',
      name: intl.formatMessage({ id: 'CRM' }),
      platforms: []
    }
  ]

  const canUsePlatform = (platform: PlatformI) => {
    return dataCheckPermission.some(p => p.canUse && p.id === platform.permission)
  }

  taggedsPlatforms.forEach((tag) => {
    platforms.forEach((platform) => {
      if (platform.tags.value.includes(tag.tag)) {
        if (searchPlatform) {
          tag.platforms = platforms.filter((platform) => platform.tags.value.indexOf(tag.tag) > -1 && (platform.name.toLowerCase().indexOf(searchPlatform.toLowerCase()) > -1 || platform.platform.indexOf(searchPlatform) > -1))
        } else {
          tag.platforms.push(platform)
        }
      }
    })
  })

  const taggedPlatformsIsEmpty = taggedsPlatforms.filter((tag) => tag.platforms.length > 0).length === 0

  const avance = () => {
    const linkResult = platforms.find((p) => p.platform === dataContentManager.platform)?.link(dataContentManager.companies || '', here)
    return linkResult || 'without-link'
  }

  const isDisabled = !(dataContentManager.platform && dataContentManager.companies.length > 0)

  const getDisabledCardProps = (canUse: boolean) => (!canUse && {
    'data-for': 'tip-global-permission',
    'data-tip': intl.formatMessage({ id: 'Conector indisponível no seu plano. Fale com nosso time de vendas para mais detalhes.' })
  })

  const getNoSelectedWorkspaceCardProps = (haveWorkspace: boolean) => (!haveWorkspace && {
    'data-for': 'tip-global-permission',
    'data-tip': intl.formatMessage({ id: 'Selecione o workspace.' })
  })

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 500)
  }, [])

  useEffect(() => {
    if (companies.length === 1 && !dataContentManager.companies && !companyIdParam) {
      setData({
        companies: [companies[0].id]
      })
    }
  }, [companies, dataContentManager])

  return (
    <Row className="flex-column">
      <Col className="text-center mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Configurar conexão' })}</h2>
      </Col>
      <Col>
      {!companyIdParam && (
          <div className='mt-4'>
            <label className='fw-bold mb-1 d-block'>
              {intl.formatMessage({ id: 'Selecione o workspace para o qual a conexão será configurada' })}
              <span className='text-error'>*</span>
            </label>
            <SelectAdvanced
              defaultValue={companies.length === 1 ? { value: companies[0].id, label: companies[0].name } : null}
              placeholder={intl.formatMessage({ id: 'Digite ou selecione uma opção' })}
              options={companies.map(t => ({ value: t.id, label: t.name })) }
              onChange={(ops: any) => setData({ companies: [ops.value] }) }
              />
          </div>
      )}
        <div className='mt-4'>
          <label className='fw-bold mb-1 d-block'>
            {intl.formatMessage({ id: 'Selecione a conexão que será configurada' })}
          </label>
          <Input icon="search" onChange={(e: any) => setSearchPlatform(e.target.value)} value={searchPlatform} placeholder={intl.formatMessage({ id: 'Buscar conexões' })} />
        </div>
        <div className='my-5' style={{ height: '35vh', overflow: 'auto' }}>
          {taggedPlatformsIsEmpty ? (
            <div className='text-center'>
              <h3 className='fw-bold'>{intl.formatMessage({ id: 'Não existem resultados para o termo digitado.' })}</h3>
              <p>{intl.formatMessage({ id: 'Verifique se escreveu corretamente e se necessário, tente novamente.' })}</p>
            </div>
          ) : (
            taggedsPlatforms.map((tag, key) => (
              tag.platforms.length > 0 &&
              <div key={key}>
                <h3 className='fw-bold mb-3 px-3 mt-4'>{tag.name}</h3>
                <Row>
                  {tag.platforms.map((platform, key) => (
                    <Col className='mb-3' xs={6} md={4} lg={3} key={key}>
                      <CardPlatform
                        {...getNoSelectedWorkspaceCardProps(canUsePlatform(platform) && !!dataContentManager.companies)}
                        {...getDisabledCardProps(canUsePlatform(platform))}
                        onClick={() => canUsePlatform(platform) && dataContentManager.companies && setData({ platform: platform.platform })}
                        isDisabled={!canUsePlatform(platform)}
                        isSelected={dataContentManager.platform === platform.platform}>
                        <IconSVG icon={platform.platform as IconSVGPropsI['icon']} />
                        <div className='mt-2'>
                          {platform.name}
                        </div>
                      </CardPlatform>
                    </Col>
                  ))}
                </Row>
              </div>
            )))}
        </div>
      </Col>
      <Col className="mt-5 text-center">
        <Button color="gray" onClick={prev}>
          {intl.formatMessage({ id: 'Cancelar' })}
        </Button>
        <Button onClick={() => null} href={avance()} className="ms-3" disabled={isDisabled}>
          {intl.formatMessage({ id: 'Avançar' })}
        </Button>
      </Col>
    </Row>
  )
}

export default SelectPlatform
