import { ArrowSquareOut } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import styled, { css } from 'styled-components'

interface ItemI extends React.HTMLAttributes<HTMLAnchorElement> {
  title: string
  href?: string
  to?: string
  routeMatch?: string[]
}

interface MenuVerticalI {
  items: ItemI[]
}

interface ItemSI {
  active: boolean
}

const MenuS = styled.ul`
  border-right: 2px solid ${({ theme }) => theme.colors.gray60};
  height: 100%;
`

const ItemS = styled.li<ItemSI>`
  list-style: none;
  padding: 1rem 0rem;

  a {
    text-decoration: none;
    ${css(({ theme }) => theme.fonts.types.body1)}
    color: ${({ theme, active }) => active ? theme.colors.dark : theme.colors.gray_nickel_dark_hover};
    border-bottom: 3px solid ${({ theme, active }) => active ? theme.colors.primary : 'transparent'};
  }
`

function Item ({ to, href, children, routeMatch }: ItemI) {
  const matchOptions = { path: to ?? '/without-link', exact: true }
  const matched = useRouteMatch(routeMatch || matchOptions)
  const [isActive, setIsActive] = useState(!!matched)

  const history = useHistory()

  useEffect(() => {
    setIsActive(!!matched)
  }, [history, matched])

  return (
    <ItemS active={isActive}>
      {href ? (
      <a className='d-flex align-items-center' href={href} target="_blank" rel="noreferrer">
        <span className='me-2'>
        {children}
        </span>
        <ArrowSquareOut size={16} />
      </a>
      ) : (
        to ? (
        <Link to={to}>
          {children}
        </Link>
        ) : children
      )}
    </ItemS>
  )
}

export function MenuVertical ({ items } : MenuVerticalI) {
  return (
    <MenuS>
      {items.map((item, key) => (
        <Item {...item} key={key}>
          {item.title}
        </Item>
      ))}
    </MenuS>
  )
}
