import useSWR from 'swr'

import { generateLinkToInstance } from 'Helpers/Company.helper'

import { fetcher } from './API/fetcher'
import useCompany from './API/useCompany.hook'

const useNeoDashLinks = ({ companyId: id }: any) => {
  const { data } = useCompany({ id })
  const { data: dataToken } = useSWR<{token: string}>(data ? `odash/${data.id}/token` : undefined, fetcher('GET'))

  const generateLink = (path?: string) => {
    if (!data || !dataToken) return '#'

    const linkWithToken = (() => {
      let redirectTo = ''
      if (path) {
        redirectTo = `&url_redirect=${path}`
      }

      return `${generateLinkToInstance({ slug: data.slug })}/login?admin_token=${dataToken.token}${redirectTo}`
    })()

    return linkWithToken
  }

  return {
    generateLink
  }
}

export default useNeoDashLinks
