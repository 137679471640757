import { addAlert } from 'Store/Actions/alerts.action'

import { apiPOST } from 'Services/Api.service'

const defaultDomain = localStorage.getItem('odash-app:domain-suffix')

export const companyAvailability = async (slug:string) => {
  return handlerCompanyDomain('company/check/domain', { domain: `${slug}` })
}

export const companyCreateRoute = async (slug:string, companyID: string) => {
  return handlerCompanyDomain(`company/${companyID}/create/domain`, { domain: `${slug}.${defaultDomain}`, slug })
}

interface handlerInterface {
  domain: string,
  slug?:string
}

const handlerCompanyDomain = async (url:string, data: handlerInterface) => {
  /**
   * if apiPOST fails, try for more 3 times
   */
  const retry = async (fn : any, retries = 3, delay = 0) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn() // Try executing the function
      } catch (error) {
        if (i < retries - 1) {
          // eslint-disable-next-line promise/param-names
          await new Promise(res => setTimeout(res, delay)) // Wait before retrying
        }
      }
    }
    throw new Error()
  }

  try {
    const apiRequest = await retry(() => apiPOST<any>(url, data))
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}
