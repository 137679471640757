import React from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'

import styled from 'styled-components'

import { getInputStyled } from '.'

const SelectStyled = styled(Select)`
  ${getInputStyled}

  cursor: pointer;

  position: relative;

  appearance: none;

  .react-select__control {
    border: none;
    height: 34px;
    min-height: 34px;
    box-shadow: none;
  }

  .react-select__control--is-focused {
    outline: none;
    border: none;
  }

  .react-select__value-container {
    height: 34px;
    padding: 0px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
`

function SelectAdvanced ({ children, ...rest }: any) {
  const { formatMessage } = useIntl()
  return (<SelectStyled classNamePrefix="react-select" {...rest} placeholder={formatMessage({ id: 'Selecione' })} noOptionsMessage={(props) => <p {...props}>{formatMessage({ id: 'Sem opções' })}</p>} />)
}

export default SelectAdvanced
