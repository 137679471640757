import styled from 'styled-components'

const SwitchStyled = styled.label`
  width: 28px;
  height: 16px;

  display: inline-block;

  position: relative;

  input {
    opacity: 0;

    width: 0;
    height: 0;
  }

  .slider {
    cursor: pointer;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    transition: 0.4s;

    background-color: #ccc;
    border-radius: 34px;
  }

  .slider::before {
    content: "";

    width: 8px;
    height: 8px;

    position: absolute;
    bottom: 4px;
    left: 4px;

    transition: 0.4s;

    background-color: white;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.colors.success};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${({ theme }) => theme.colors.success};
  }

  input:checked + .slider::before {
    transform: translateX(12px);
  }
`

function Switch ({ children, ...props }: any) {
  return (
    <div className="d-flex align-items-center">
      <SwitchStyled>
        <input type="checkbox" {...props} />
        <span className="slider"></span>
      </SwitchStyled>
      {children && children}
    </div>
  )
}

export default Switch
