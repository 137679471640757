import { Info } from '@phosphor-icons/react'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Col,
  Row,
  SelectAdvanced,
  ToggleSwitch,
  Tooltip
} from 'DesignSystem'
import LoadingDots from 'DesignSystem/LoadingDots'
import styled from 'styled-components'

import {
  ConnectionFormContext,
  ConnectionFormContextProvider
} from 'Components/ConnectionForm/ConnectionForm.context'

import useAccount from 'Hooks/API/useAccount.hook'
import useCompany from 'Hooks/API/useCompany.hook'
import useDataGroup from 'Hooks/API/useDataGroup.hook'
import useIsFirstAccess from 'Hooks/useIsFirstAccess.hook'
import useQuery from 'Hooks/useQuery.hook'

const SmallParag = styled.p`
  font-size: 12px;
  color: #717171;
  line-height: 20px;
`

function SelectAccount ({
  next,
  prev,
  setSizeDialog,
  setData,
  dataContentManager
}: any) {
  const history = useHistory()
  const intl = useIntl()
  const { isLoading } = useContext(ConnectionFormContext)
  const { isFirstAccess } = useIsFirstAccess()
  const query = useQuery()
  const [dataGroupId, setDataGroupId] = useState(null)
  const { data } = useAccount({ id: dataContentManager.id })
  const { id: companyIdParam } = useParams<any>()
  const { data: dataCompany } = useCompany({ id: companyIdParam || query.get('company_id') || '' })
  const { data: dataGroups = { list: [] }, isLoading: isLoadingInnerAccounts, create } = useDataGroup({
    platform: data?.platform,
    companySlug: dataCompany?.slug || ''
  })
  const isDisabled = isLoadingInnerAccounts || (isFirstAccess && dataGroups && dataGroups.list.length > 0 && !dataGroupId)
  const [chooseAccounts, setChooseAccount] = useState(
    isFirstAccess || (!isLoadingInnerAccounts && dataGroups && dataGroups.list.length === 0)
  )

  useEffect(() => {
    setSizeDialog('sm')
  }, [isLoading, setSizeDialog])

  const update = () => {
    if (dataGroupId && data?.platform && dataCompany?.slug) {
      const { pendingRequests = [] } = dataContentManager

      setData({
        pendingRequests: [...pendingRequests, 'create-datagroup']
      })

      create({
        id: dataGroupId,
        platform: data.platform,
        companySlug: dataCompany.slug || ''
      }).then(() => {
        setData({
          pendingRequests: pendingRequests.filter((r: string) => r !== 'create-datagroup'),
          hasInnerAccount: true
        })
      })
    }
    next()
  }

  const onBack = () => {
    const params = new URLSearchParams(window.location.search)
    params.set('new-connection', 'true')
    params.delete('edit')
    history.replace({
      search: params.toString()
    })
  }

  return !isLoading ? (
    <Row className="flex-column">
      <Col className="text-center mt-3">
        <h2 className="fw-extrabold">
          {isFirstAccess ? (
            intl.formatMessage({ id: 'Configure sua primeira conexão' })
          ) : (
            intl.formatMessage({ id: 'Configurar conexão' })
          )}
        </h2>
        <p className="text-start body1 mt-3">
          {intl.formatMessage({
            id: 'Selecione a conta de anúncio que será utilizada para que a importação de resultados dos últimos 30 dias das campanhas seja realizada.'
          })}
          <span>
            <Tooltip
              content={
                <div>
                    {isFirstAccess ? (
                      <p>
                        {intl.formatMessage({ id: 'Neste momento, você indicará uma conta de anúncio para que façamos a importação dos seus primeiros resultados para o NeoDash.' })}
                        <br/>
                        <br/>
                        {intl.formatMessage({ id: 'Posteriormente, você poderá ajustar o período dos resultados e fazer configurações mais abrangentes acessando a edição dos Grupos de Dados do NeoDash.' })}
                      </p>
                    ) : (
                      <p>
                        {intl.formatMessage({ id: 'Caso seja necessário, você poderá ajustar o período dos resultados acessando a edição dos Grupos de Dados do NeoDash.' })}
                      </p>
                    )}
                  <p className='mt-2'>
                    {intl.formatMessage({ id: 'Para saber mais' })}{' '}
                    <a className='text-primary' href="https://wiki.neodash.ai/base-de-conhecimento/configurando-um-grupo-de-dados/" target='_blank' rel='noreferrer'>
                      {intl.formatMessage({ id: 'clique aqui.' })}
                    </a>
                  </p>
                </div>
              }
              position='left'
            >
              <Info className="text-gray mt-1" size={16} />
            </Tooltip>
          </span>
        </p>
      </Col>
      {isLoadingInnerAccounts && (
        <div className='d-flex justify-content-center mt-5' >
          <LoadingDots />
        </div>
      )}
      {!isLoadingInnerAccounts && (!isFirstAccess || (dataGroups && dataGroups.list.length === 0)) && (
        <div>
          <div className="mt-5 d-flex align-items-center">
            <ToggleSwitch
              checked={chooseAccounts}
              onChange={() => setChooseAccount(!chooseAccounts)}
            />
            <label className="ms-2" htmlFor="">
              {intl.formatMessage({
                id: 'Configurar conta de anúncio e campanhas manualmente.'
              })}
            <span>
              <Tooltip
                content={
                  <div>
                    {intl.formatMessage({ id: 'Se desativado, você deverá acessar seus Grupos de Dados, localizados no Painel Administrativo, e configurar a conta de anúncio e quais campanhas serão utilizadas para realizar a importação de resultados.' })}
                    <p className='mt-2'>
                      {intl.formatMessage({ id: 'Para saber mais' })}{' '}
                      <a className='text-primary' href="https://wiki.neodash.ai/base-de-conhecimento/configurando-um-grupo-de-dados/" target='_blank' rel='noreferrer'>
                        {intl.formatMessage({ id: 'clique aqui.' })}
                      </a>
                    </p>
                  </div>
                }
                position='left'
              >
                <Info className="text-gray mt-1" size={16} />
              </Tooltip>
            </span>
            </label>
          </div>
        </div>
      )}
      {(chooseAccounts || isFirstAccess) && (!isLoadingInnerAccounts && dataGroups && (
        <div className="mt-4 mb-4">
          <label className="fw-bold" htmlFor="">
            {intl.formatMessage({ id: 'Conta de anúncio:' })}{' '}
            <span className="text-error">*</span>
          </label>
          <SelectAdvanced
            className="mt-3"
            onChange={(selected: any) => setDataGroupId(selected.value)}
            options={dataGroups.list.map((acc) => (
              {
                label: acc.name,
                value: acc.id
              }
            ))}
          />
          {(dataGroups.list.length === 0 && isFirstAccess) && (
            <SmallParag className='mt-4 text-color-gray-40'>
              {intl.formatMessage({ id: 'Recomendamos que para sua primeira conexão, exista uma conta de anúncio que possa ser selecionada para que seus dados de mídia sejam importados e fiquem disponíveis na plataforma. Caso configure uma conexão sem contas de anúncio disponíveis, não haverão dados para serem importados e exibidos.' })}
            </SmallParag>
          )}
        </div>
      ))}
      <Col className="mt-4 text-center">
        <Button color="gray" onClick={onBack}>
          {intl.formatMessage({ id: 'Voltar' })}
        </Button>
        <Button onClick={update} className="ms-3" disabled={isDisabled}>
          {intl.formatMessage({ id: 'Avançar' })}
        </Button>
      </Col>
    </Row>
  ) : (
    <div className="my-5">
      <LoadingDots />
    </div>
  )
}

const SelectAccountWithContext = (props: any) => {
  return (
    <ConnectionFormContextProvider>
      <SelectAccount {...props} />
    </ConnectionFormContextProvider>
  )
}

export default SelectAccountWithContext
