import type { UserCreate } from 'Interfaces/user.interface'

import { addAlert } from 'Store/Actions/alerts.action'

import { apiPOST, apiGET } from 'Services/Api.service'

export const createUser = async (fields:UserCreate) => {
  const request = await apiPOST<any>('user', { ...fields, frontHost: window.location.origin })
  const response = await request

  if (response.status === 'error') {
    throw new Error(response.message ?? '')
  } else if (response.url_redirect) {
    window.location.href = response.url_redirect
    return { type: 'NOTHING' }
  } else if (fields.invite) {
    return { payload: { type: '' } }
  }
  return addAlert({ title: 'Cadastro feito. Para continuar, ative seu acesso através do e-mail enviado.', type: 'success' })
}

export const activationSignup = async (activationCode:string) => {
  const request = await apiGET<any>(`activate/${activationCode}`)
  const response = await request

  if (response.status === 'error') {
    return addAlert({ title: response.message ?? '', type: 'error' })
  } else if (response.url_redirect) {
    window.location.href = response.url_redirect
    return { type: 'NOTHING' }
  } else {
    return addAlert({ title: 'E-mail cadastrado com sucesso!', type: 'success' })
  }
}
