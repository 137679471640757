import type { UserLogin } from 'Interfaces/user.interface'
import jscookie from 'js-cookie'

import { apiGET, apiPOST } from 'Services/Api.service'

import * as localStorage from 'Helpers/Localstorage.helper'

export const authUser = async (fields: UserLogin) => {
  const login = await apiPOST<any>('login', fields)
  const response = await login

  if (response.status === 'error') {
    throw new Error(response.message ?? '')
  } else {
    if (response.url_redirect) {
      window.location.href = response.url_redirect
      return { type: 'NOTHING' }
    } else {
      localStorage.setItem('odash-app:user_info', JSON.stringify(response))
      return { type: 'USER_DATA', payload: { ...fields, ...response } }
    }
  }
}

export const logoutUser = () => {
  const payload = null
  const type = 'USER_AUTH'

  localStorage.removeItem('odash-app:user')
  localStorage.removeItem('odash-app:user_info')
  localStorage.removeItem('odash-app:show-welcome-create-workspace')
  jscookie.remove(process.env.REACT_APP_TOKEN_COOKIE_NAME || '')
  window.location.reload()

  return { type, payload }
}

export const checkUserAPIToken = async (slug: string, api_token: string, callback?: any) => {
  try {
    const response = await apiGET<any>(`check/userOnWorkspace?slug=${slug}&api_token=${api_token}`)

    localStorage.setItem('odash-app:user_info', JSON.stringify(response))

    callback && callback(response)
    return { type: 'USER_DATA', payload: { ...response } }
  } catch (e: any) {
    callback && callback({ status: 'error', message: e.message })
    return { type: 'NOTHING' }
  }
}
