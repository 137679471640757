import type { ReactNode } from 'react'

import { Col, Row } from 'DesignSystem'
import styled from 'styled-components'

import Logo from '../Logo'

const RootS = styled(Row)`
  width: 444px;
  max-width: 100%;
  margin: 0 auto;

  a {
    color: ${({ theme }) => theme.colors.primary_hover};
  }
`
interface ContentProps {
  children: ReactNode
  title?: string
}

function Content ({ children, title } : ContentProps) {
  return (
    <RootS>
      <Row className='justify-content-start px-0'>
        <Col className="mt-4 px-0 mt-lg-0"xs={5}>
          <Logo />
        </Col>
        <Col className="mt-4 px-0" xs={12}>
          {title && (
            <h3 className="fw-bold">
              {title}
            </h3>
          )}
        </Col>
      </Row>
      <Row className="flex-column align-items-center text-center w-100 px-0">
        <Col className="mt-3 px-0" xs={12}>
          {children}
        </Col>
      </Row>
    </RootS>
  )
}

export default Content
