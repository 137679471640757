import { useIntl } from 'react-intl'

import { Row, Col } from 'DesignSystem'
import type DefaultProps from 'DesignSystem/Types'
import styled from 'styled-components'

import TitleWithSpanAsGradient from 'Components/TitleWithSpanAsGradient'

import bannerImg from '../../../public/images/banner-login.png'
import HelpLinks from './Components/HelpLinks'

const MainContainer = styled.div`
  height: 100vh;

  margin: 0 auto;

  background-color: ${(props) => props.theme.colors.white};

  max-width: 1920px;

  .mt-4rem {
    margin-top: 4rem;
  }

  @media (max-width: 1500px) {
    .main-banner {
      max-width: 60%;
    }
  }
`

const SplitedImage = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    list-style: none;
    padding: 0;
  }
`

function AuthLayout ({ children }: DefaultProps) {
  const intl = useIntl()
  const clients = [
    'loreal',
    'neoenergia',
    'reclame-aqui',
    'samcart',
    'arajet',
    'atob',
    '3-graus'
  ]

  return (
    <MainContainer>
      <Row>
        <Col className="d-none d-md-block p-0" xs={7}>
          <SplitedImage className="px-5 d-flex justify-content-center align-items-start" style={{ height: '100%' }}>
            <TitleWithSpanAsGradient className="m-0 fw-bold text-white">
              <span>
                {intl.formatMessage({ id: 'Integre, analise e decida' })}:
              </span>{' '}<br />
              {intl.formatMessage({ id: 'todos os seus' })}{' '}
              <span>
                {intl.formatMessage({ id: 'dados de marketing' })},<br />
              </span>{' '}
              {intl.formatMessage({ id: 'em um só lugar.' })}
            </TitleWithSpanAsGradient>
            <div className='my-5 main-banner mx-auto'>
              <img className='w-100' src={bannerImg} alt='' />
            </div>
            <div className='mt-4 w-100'>
              <p className='text-white mb-4 fw-semibold'>{intl.formatMessage({ id: 'Usado e aprovado por empresas no mundo todo.' })}</p>
              <ul className='d-flex justify-content-between gap-3 w-100 list-none'>
                {clients.map((client, key) => (
                  <li key={key}>
                    <img className='w-100' src={`/images/login-logos/${client}.png`} alt="" />
                  </li>
                ))}
              </ul>
            </div>
          </SplitedImage>
        </Col>
        <Col style={{ paddingTop: '4rem', overflow: 'auto', height: '100vh', position: 'relative' }}>
          {children}
          <div className='w-100'>
            <HelpLinks />
          </div>
        </Col>
      </Row>
    </MainContainer>
  )
}

export default AuthLayout
