import React from 'react'

import styled from 'styled-components'

const SwitchContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 28px;
  height: 16px;
`

const Slider = styled.div<any>`
  position: absolute;
  top: 3px;
  left: ${(props: any) => (props.checked ? '16px' : '2px')};
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.2s;
`

const StyledSwitch = styled.div<any>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props: any) => (props.checked ? 'green' : 'gray')};
  border-radius: 24px;
  transition: background-color 0.2s;
  cursor: pointer;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

const ToggleSwitch = ({ checked, onChange }: any) => (
  <SwitchContainer onClick={onChange}>
    <HiddenCheckbox checked={checked} onChange={onChange} />
    <StyledSwitch checked={checked}>
      <Slider checked={checked} />
    </StyledSwitch>
  </SwitchContainer>
)

export default ToggleSwitch
