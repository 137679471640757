import React, { createContext, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import useAccount from 'Hooks/API/useAccount.hook'

const ConnectionFormContext = createContext<any>({})

const ConnectionFormContextProvider = ({ children } : any) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { account_id } = useParams<any>()
  const id = searchParams.get('edit') ? searchParams.get('edit') : account_id
  const { data, isLoading, update } = useAccount({ id })
  const [dataGroupId, setDataGroupId] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const goUpdate = async () => {
    if (!name) return

    return await update({
      name,
      description
    })
  }

  return (
    <ConnectionFormContext.Provider
      value={{
        dataGroupId,
        setDataGroupId,
        name,
        setName,
        description,
        setDescription,
        data,
        isLoading,
        goUpdate
      }}>
      {children}
    </ConnectionFormContext.Provider>
  )
}

export { ConnectionFormContext, ConnectionFormContextProvider }
