import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { generateLinkTo } from 'Helpers/Route.helper'

import { ActionsS, RootWorkspaceCard } from './WorkspaceCard.styled'

interface WorkspaceCardI {
  workspaceName: string
  workspaceId: string
  logoSrc: string
  bgColor: string
  slug: string
}

const WorkspaceCard = (props: WorkspaceCardI) => {
  const history = useHistory()
  const intl = useIntl()

  return (
    <RootWorkspaceCard
      bgColor={props.bgColor}
    >
      <h4 className='fw-bold text-center'>{props.workspaceName}</h4>
      <img src={props.logoSrc} alt="Workspace Logo" />
      <div className="action-btn-wrapper">
        <h4 className='fw-bold text-center'>{props.workspaceName}</h4>
        <ActionsS>
          <a href={`/odash/${props.workspaceId}/${props.slug}`} target='_blank' rel="noreferrer">
            <img src="/images/icon-desktop.svg" alt="Dashboard" />
            {intl.formatMessage({ id: 'Acessar' })}
          </a>
          <a href={`/odash/${props.workspaceId}/${props.slug}/admin`} target='_blank' rel="noreferrer">
            <img src="/images/icon-user-settings.svg" alt="Admin" />
            Admin
          </a>
          <button
            onClick={(event) => {
              event.stopPropagation()
              history.push(generateLinkTo('companyEdit', { id: props.workspaceId }))
            }}
          >
            <img src="/images/icon-edit.svg" alt="Edit" />
            {intl.formatMessage({ id: 'edit' })}
          </button>
        </ActionsS>
      </div>
    </RootWorkspaceCard>
  )
}

export default WorkspaceCard
