import useSWR from 'swr'

import { apiPOST } from 'Services/Api.service'

import { createQuery } from 'Helpers/Url.helper'

import { fetcher } from './fetcher'
import type { AccountI } from './useAccounts.hook'

export interface DataGroupI {
  id: string,
  name: string,
  currency: string,
  tokenManagerId: string,
}

export interface RawDataGroupI {
  datalog: string,
  list: Array<DataGroupI>
}

interface UseDataGroupParamsI {
  platform: AccountI['platform'] | string | undefined
  companySlug: string
}

interface UseDataGroupReturnI {
  data: RawDataGroupI | undefined,
  isLoading: boolean,
  isError: any,
  mutate: any,
  create: any,
}

const useDataGroup = (params : UseDataGroupParamsI) : UseDataGroupReturnI => {
  const { platform, companySlug } = params

  const query = createQuery()
  query.addParams('slug', companySlug)

  const url = (platform && companySlug) ? (
    `${process.env.REACT_APP_API_URL}accounts/listInnerAccounts/${platform}${query.getValue()}`
  ) : ('')

  const { data, error, mutate } = useSWR<RawDataGroupI>(
    url,
    fetcher('GET')
  )

  const create = async (data: any) => {
    const { platform, id, companySlug } = data
    return await apiPOST(`accounts/createDataGroup/${platform}/${id}?slug=${companySlug}`)
  }

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    create
  }
}

export default useDataGroup
