import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { addAlert } from 'Store/Actions/alerts.action'
import { checkUserAPIToken } from 'Store/Actions/User/auth.action'

import { generateLinkTo } from 'Helpers/Route.helper'

const LoginFromExternal = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const generateToken = async () => {
    const params = (new URL(window.location.href)).searchParams
    const slug = params.get('slug')
    const api_token = params.get('api_token')
    const next = params.get('next')
    if (slug && api_token) {
      checkUserAPIToken(slug || '', api_token || '', (response: any) => {
        if (response.status === 'error') {
          dispatch(addAlert({ title: response.message, type: 'error' }))
          if (window.location.pathname) {
            history.replace(`/login?next=${window.location.pathname}`)
          } else {
            history.replace('/login')
          }
        } else {
          if (next) {
            history.replace(next)
          } else {
            history.replace(generateLinkTo('home'))
          }
        }
      }).then((response: any) => {
        dispatch(response)
      })
    }
  }

  useEffect(() => {
    generateToken()
  }, [])

  return (
    null
  )
}

export default LoginFromExternal
