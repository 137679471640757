import React, { useState, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'

import { Col, Input, Switch, Button, InputPassword } from 'DesignSystem'
import AuthLayout from 'Layouts/AuthLayout'
import Content from 'Layouts/AuthLayout/Components/Content'
import { isEmpty } from 'lodash'

import { addAlert } from 'Store/Actions/alerts.action'
import { authUser } from 'Store/Actions/User/auth.action'
import { activationSignup } from 'Store/Actions/User/users.action'

import ListButtonFederatedLoginComponent from 'Components/ListButtonFederatedLogin/ListButtonFederatedLogin.component'
import TextDiviser from 'Components/TextDiviser/TextDiviser.component'

import { generateLinkTo } from 'Helpers/Route.helper'

type LoginPropsI = {
  isActiveCode?: boolean
}

function Login (props: LoginPropsI) {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const dispatch = useDispatch()
  const intl = useIntl()

  const params = useParams<{ activationCode: string }>()

  useEffect(() => {
    if (!props.isActiveCode) return

    async function requestAPI () {
      setLoading(true)

      try {
        const { activationCode } = params
        const resAction = await activationSignup(activationCode)
        dispatch(resAction)
        clearState()
      } catch (e:any) {
        dispatch(
          addAlert({ title: e.message, type: 'error' })
        )
      }

      history.push(generateLinkTo('login'))
    }
    requestAPI()

    return () => {
      clearState()
    }
  }, [dispatch, params])

  const clearState = useCallback(() => {
    setEmail('')
    setPassword('')
    setLoading(false)
  }, [loading])

  function validFields () {
    if (!isEmpty(email) && !isEmpty(password)) {
      return true
    } else {
      dispatch(
        addAlert({
          title: 'Preencha os campos corretamente!',
          type: 'error'
        })
      )
    }
  }

  async function requestAPI () {
    setLoading(true)

    try {
      dispatch(await authUser({ email, password }))
      clearState()
    } catch (e: any) {
      dispatch(addAlert({ title: e.message, type: 'error' }))
      setLoading(false)
    }
  }

  async function login (event: any): Promise<void> {
    event.preventDefault()
    if (validFields()) {
      await requestAPI()
    }
  }

  return (
    <AuthLayout>
      <Content title={intl.formatMessage({ id: 'Faça login no NeoDash' })}>
        <form onSubmit={login}>
          <div className='d-flex flex-column text-start'>
            <label className='mb-2 fw-semibold'>{intl.formatMessage({ id: 'E-mail' })}</label>
            <Input
              placeholder={intl.formatMessage({ id: 'seu@email.com' })}
              value={email}
              onChange={(event: any) => setEmail(event?.target?.value)}
              />
          </div>
          <div className='d-flex flex-column text-start mt-4'>
            <label className='mb-2 fw-semibold'>{intl.formatMessage({ id: 'Senha' })}</label>
            <InputPassword
              placeholder={intl.formatMessage({ id: 'Digite aqui' })}
              value={password}
              onChange={(event: any) => setPassword(event?.target?.value)}
            />
          </div>
          <div className="d-flex my-3 align-items-center">
            <Col>
              <Switch>
                <label className="body2 ms-2 fw-bold">{intl.formatMessage({ id: 'Lembre de mim' })}</label>
              </Switch>
            </Col>
            <Col className="text-end">
              <Link
                className="body2 fw-bold text-decoration-none text-black"
                to={generateLinkTo('forgotPassword')}
              >
                {intl.formatMessage({ id: 'Esqueci minha senha' })}
              </Link>
            </Col>
          </div>
          <div className="d-grid mt-5">
            <Button color="primary" disabled={loading}>
              {intl.formatMessage({ id: 'Login' })}
            </Button>
          </div>
        </form>
        <div className="my-2 my-lg-4">
          <TextDiviser>{intl.formatMessage({ id: 'ou' })}</TextDiviser>
        </div>
        <div className="d-grid mt-3">
          <ListButtonFederatedLoginComponent showMinimal={true} />
        </div>
        <div className='d-grid mt-3'>
          <p>
            {intl.formatMessage({ id: 'Ainda não é registrado?' })} {' '}
            <Link className='body-2 fw-bold text-decoration text-primary-dark' to={generateLinkTo('signupSimple')}>
              {intl.formatMessage({ id: 'Cadastre-se' })}
            </Link>
          </p>
        </div>
      </Content>
    </AuthLayout>
  )
}

export default Login
