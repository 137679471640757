import type { FC } from 'react'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import type StoreInterface from 'Interfaces/store.interface'
import styled from 'styled-components'

import { addAlert } from 'Store/Actions/alerts.action'

import useQuery from 'Hooks/useQuery.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

import ItemAlert from './item.component'

const AlertsWrapper = styled.div`
  z-index: 10001;

  position: fixed;
  top: 4rem;
  right: 2rem;

  & > * {
    margin-top: 1rem;
  }
`

const Alerts: FC = () => {
  const alerts = useSelector((state: StoreInterface) => state.alerts)
  const query = useQuery()
  const dispatch = useDispatch()
  const history = useHistory()
  const intl = useIntl()
  const error = query.get('error')
  const isConnectionOnCompany = useRouteMatch(generateLinkTo('companyEditStep'))
  const isConnectionPage = useRouteMatch(generateLinkTo('connections'))
  const isSomePageRelatedToConnection = isConnectionOnCompany || isConnectionPage

  console.log({ isConnectionOnCompany, isConnectionPage, isSomePageRelatedToConnection })

  useEffect(() => {
    if (error) {
      let defaultMessageError = intl.formatMessage({ id: `error.${error}` })

      if (isSomePageRelatedToConnection) {
        defaultMessageError = intl.formatMessage({ id: 'Não foi possível adicionar/renovar uma conexão!' })
      }

      dispatch(
        addAlert({
          title: defaultMessageError,
          type: 'error'
        })
      )
      /**
       * Remover o erro da URL with history API
       */
      query.delete('error')
      history.replace({
        search: query.toString()
      })
    }
  }, [error])

  return alerts?.items?.length ? (
    <AlertsWrapper>
      {alerts?.items.map((item, key) => (
        <ItemAlert
          key={key}
          index={key}
          title={item.title}
          severity={item.type}
        >
          {item?.content && <FormattedMessage id={item?.content} />}
        </ItemAlert>
      ))}
    </AlertsWrapper>
  ) : null
}

export default Alerts
