import styled from 'styled-components'

const TitleWithSpanAsGradient = styled.h1<any>`
  font-size: 2.7rem;
  color: ${(props) => props.color ? props.color : '#FCFCFC'};
  font-weight: 600;

  @media (max-width: 1100px) or (max-height: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  span {
    background: rgb(28,183,213);
    background: linear-gradient(90deg, rgba(28,183,213,1) 0%, rgba(198,198,197,1) 72%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export default TitleWithSpanAsGradient
