import { useIntl } from 'react-intl'

import { Col, Row } from 'DesignSystem'
import LoadingDots from 'DesignSystem/LoadingDots'

function WaitingToSave ({
  next,
  dataContentManager
}: any) {
  const intl = useIntl()
  const { pendingRequests } = dataContentManager

  if (pendingRequests && pendingRequests.length === 0) {
    next()
  }

  return (
    <Row className="flex-column">
      <Col className="text-center mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Quase lá...' })}</h2>
        <p className='text-start body2'>
          {intl.formatMessage({ id: 'Estamos salvando todas as suas configurações e dentro de instantes tudo estará funcionando...' })}
        </p>
      </Col>
      <Col className='pt-3'>
        <LoadingDots />
      </Col>
    </Row>
  )
}

export default WaitingToSave
