import { ArrowSquareOut } from '@phosphor-icons/react'
import { useIntl } from 'react-intl'

import styled, { css } from 'styled-components'

const HelpLinksRoot = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  a {
      ${props => css(props.theme.fonts.types.body3)};
  }

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    left: 0;
    margin-top: 1.5rem;

    a {
      ${props => css(props.theme.fonts.types.caption1)};
    }
  }

  @media (max-height: 700px) {
    left: 0;
  }
`

function HelpLinks () {
  const intl = useIntl()

  return (
    <HelpLinksRoot>
      <div className="d-flex justify-content-center gap-3 text-center py-3">
        <div className='p-0'>
          <a className="text-decoration-none text-black" href={intl.formatMessage({ id: 'https://neodash.ai/sobre-nós/' })} target='_blank' rel="noreferrer">
            {intl.formatMessage({ id: 'Sobre' })}
            <ArrowSquareOut className='ms-1' />
          </a>
        </div>
        <div className='p-0'>
          <a className="text-decoration-none text-black" href="https://wiki.neodash.ai/" target="_blank" rel="noreferrer">
            {intl.formatMessage({ id: 'Central de Ajuda' })}
            <ArrowSquareOut className='ms-1' />
          </a>
        </div>
        <div className='p-0'>
          <a className="text-decoration-none text-black" href={intl.formatMessage({ id: 'https://neodash.ai/termo-de-uso' })} target="_blank" rel="noreferrer">
            {intl.formatMessage({ id: 'Termos de Uso' })}
            <ArrowSquareOut className='ms-1' />
          </a>
        </div>
        <div className='p-0'>
          <a className="text-decoration-none text-black" href={intl.formatMessage({ id: 'http://neodash.ai/privacidade-seguranca' })} target='_blank' rel="noreferrer">
            {intl.formatMessage({ id: 'Política de Privacidade' })}
            <ArrowSquareOut className='ms-1' />
          </a>
        </div>
      </div>
    </HelpLinksRoot>
  )
}

export default HelpLinks
