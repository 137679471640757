import { useLocation } from 'react-router-dom'

const useIsFirstAccess = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const isSignup = query.get('is_signup') === 'true'
  const isSuccessFromCheckout = query.get('success') === 'true' && query.get('session_id') !== null

  return {
    isFirstAccess: isSignup || isSuccessFromCheckout,
    isSignup,
    isSuccessFromCheckout
  }
}

export default useIsFirstAccess
