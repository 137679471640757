import { Plugs, PlusCircle } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import permissions from 'Constants/permissions.constants'
import { Button, Col, Row } from 'DesignSystem'
import cloneDeep from 'lodash/cloneDeep'

import Breadcrumbs from 'Components/Breadcrumbs'
import CheckboxFilter from 'Components/CheckboxFilter'
import ConnectionCard from 'Components/ConnectionCard'
import useCreateConnectionFlowManager from 'Components/CreateConnectionFlow'
import useEditConnectionFlowManager from 'Components/EditConnectionFlow'
import IconSVG from 'Components/IconSVG'
import type { IconNameI } from 'Components/IconSVG/IconSVG.component'
import NotFoundSearch from 'Components/NotFoundSearch/NotFoundSearch.component'
import SearchAdvanced from 'Components/SearchAdvanced'
import SelectFilter from 'Components/SelectFilter'
import SelectPlatformFilter from 'Components/SelectPlatformFilter'
import Tag from 'Components/Tag'
import TemplateDefault from 'Components/TemplateDefault'
import Text from 'Components/Text'

import type { AccountI } from 'Hooks/API/useAccounts.hook'
import useAccounts from 'Hooks/API/useAccounts.hook'
import useCheckPermission from 'Hooks/API/useCheckPermission.hook'
import useCompanies from 'Hooks/API/useCompanies.hook'
import useUser from 'Hooks/API/useUser.hook'
import useQuery from 'Hooks/useQuery.hook'

import { MicroData } from 'Helpers/MicroData.helper'
import { generateLinkTo } from 'Helpers/Route.helper'

import ListConnectionLoader from './Components/ListConnectionLoader.component'
import { SearchFilterS, TagsS, FilterS } from './Connections.styled'

interface ParamsRouteI {
  account_id?: string
  company_id?: string
}

interface ConnectionsPropsI {
  isPocket?: boolean,
  idCompany?: string,
  isFullContainer?: boolean,
}

const Connections = (props: ConnectionsPropsI) => {
  const { isPocket = false, idCompany, isFullContainer } = props
  const intl = useIntl()
  const query = useQuery()
  const history = useHistory()
  const location = useLocation()
  const { company_id } = useParams<ParamsRouteI>()
  const isConnectionNew = query.get('new-connection') === 'true'
  const [searchAdvancedIsOpen, setSearchAdvancedIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState(query.get('search') || '')
  const { data: checkPermissionToManageTokens } = useCheckPermission([permissions.MANAGE_TOKENS], idCompany)
  const { data: dataUser, isLoading: isLoadingUser } = useUser()

  /**
   * Advanced filters
   */
  const [idsCompanyFilters, setIdsCompanyFilters] = useState<Set<string>>(new Set(query.get('companies')?.split(' ') ?? []))
  const [plataformConnectionFilters, setPlatformConnectionFilters] = useState<Set<string>>(new Set(query.get('connections')?.split(' ') ?? []))
  const [fullData, setFullData] = useState<boolean>(query.get('fulldata') === 'true' ?? false)

  const handleCloseOnEditConnection = () => {
    query.delete('edit')

    history.replace({
      search: query.toString()
    })
  }

  const handleChangeSearch = (value: string) => {
    setSearchValue(value)
  }

  const { data: companies, isLoading: isLoadingCompanies } = useCompanies()

  const { data = [], isLoading, mutate: mutateAccounts } = useAccounts({
    isGroupedByPlatform: false,
    withFullData: fullData,
    search: searchValue || '',
    companies: isPocket && idCompany ? [idCompany] : Array.from(idsCompanyFilters),
    platforms: Array.from(plataformConnectionFilters)
  })

  const { content: contentCreateConnection, start: startCreateConnection } = useCreateConnectionFlowManager({
    idCompany: idCompany || company_id,
    onClose: () => {
      const params = new URLSearchParams(window.location.search)
      params.delete('new-connection')

      history.replace({
        search: params.toString()
      })
      mutateAccounts()
    }
  })
  const { content: contentEditConnection, start: startEditConnection } = useEditConnectionFlowManager({
    onClose: () => {
      mutateAccounts()
      handleCloseOnEditConnection()
    }
  })

  const handleToggleSearchAdvanced = () => setSearchAdvancedIsOpen(!searchAdvancedIsOpen)

  const handleOnClearSearchAdvanced = () => {
    setIdsCompanyFilters(new Set())
    setPlatformConnectionFilters(new Set())
  }

  const handleOnChangeCompany = (value: string) => {
    const cloneIdsCompanyFilters = cloneDeep(idsCompanyFilters)

    cloneIdsCompanyFilters.add(value)
    setIdsCompanyFilters(cloneIdsCompanyFilters)
  }

  const handleOnCloseCompany = (value: string) => {
    const cloneIdsCompanyFilters = cloneDeep(idsCompanyFilters)

    cloneIdsCompanyFilters.delete(value)
    setIdsCompanyFilters(cloneIdsCompanyFilters)
  }

  const handleOnChangeConnections = (value: string) => {
    const clonePlataformConnectionFilters = cloneDeep(plataformConnectionFilters)

    clonePlataformConnectionFilters.add(value)
    setPlatformConnectionFilters(clonePlataformConnectionFilters)
  }

  const handleOnChangeFullData = (check: boolean) => {
    setFullData(check)
  }

  const handleOnCloseConnections = (value: string) => {
    const clonePlataformConnectionFilters = cloneDeep(plataformConnectionFilters)

    clonePlataformConnectionFilters.delete(value)
    setPlatformConnectionFilters(clonePlataformConnectionFilters)
  }

  const handleStartCreation = () => {
    query.set('new-connection', 'true')

    history.replace({
      search: query.toString()
    })
  }

  useEffect(() => {
    if (isConnectionNew && !isLoadingCompanies && !contentCreateConnection) {
      if (companies.length > 0) {
        startCreateConnection()
      }
    }
  }, [isLoadingCompanies, companies, isConnectionNew, contentCreateConnection, history, startCreateConnection])

  useEffect(() => {
    if (searchValue) {
      query.set('search', searchValue)
    }
    query.set('fulldata', fullData.toString())

    if (idsCompanyFilters.size > 0) {
      query.set('companies', Array.from(idsCompanyFilters).join('+'))
    }

    if (plataformConnectionFilters.size > 0) {
      query.set('connections', Array.from(plataformConnectionFilters).join('+'))
    }

    history.replace({
      search: query.toString()
    })
  }, [idsCompanyFilters, plataformConnectionFilters, searchValue, fullData])

  useEffect(() => {
    const accountId = query.get('edit')
    if (accountId) {
      startEditConnection({
        id: accountId
      })
    }
  }, [location, data])

  /**
   * Views
  */
  const iconToSearchAdvanced = (
    <FilterS onClick={handleToggleSearchAdvanced} >
      <IconSVG icon='filter'/>
    </FilterS>
  )

  const topExtra = (
    <SearchAdvanced isOpen={searchAdvancedIsOpen} onClear={handleOnClearSearchAdvanced} data-component-type={MicroData.CONTENT}>
      {!(isPocket && idCompany) && (
        <SelectFilter
          icon='company'
          placeholder={intl.formatMessage({ id: 'Selecionar workspace' })}
          onChange={handleOnChangeCompany}
        >
          {companies.map(company => (
            <Text key={company.id} id={company.id} data-search={company.name}>{company.name}</Text>
          ))}
        </SelectFilter>
      )}
      <SelectPlatformFilter onChange={handleOnChangeConnections} />
      <CheckboxFilter checked={fullData} onChange={handleOnChangeFullData} label={intl.formatMessage({ id: 'Com status de token?' })} />
    </SearchAdvanced>
  )

  const NoConnectionsYet = () => (
    <div className='text-center w-100'>
      <h2 className='fw-bold'>{intl.formatMessage({ id: 'Você não possui conexões configuradas.' })}</h2>
      <div className='mt-3'>
        <Plugs weight='fill' size='5rem' />
      </div>
      <div className='mt-3'>
        <CreateConnectionButton />
      </div>
    </div>
  )

  const generateListCardConnection = (dataList: AccountI[]) => (
    dataList.map((account, index) => {
      return (
        <Col xs={6} md={3} xxl={2} key={index} className='mt-4 px-2'>
          <ConnectionCard
            companyID={idCompany || company_id}
            onDetail={() => {
              if (idCompany || company_id) {
                history.push(generateLinkTo('companyConnectionEdit', { id: idCompany || company_id, account_id: account.id }))
              } else {
                history.push(generateLinkTo('connectionsShow', { account_id: account.id }))
              }
            }}
            logoSrc={account.platform}
            connectionID={account.id}
            connectionName={account.account_name || 'Vazio'}
            platform={account.long_platform}
          />
        </Col>
      )
    })
  )

  const crumbs: any = [
    {
      name: intl.formatMessage({ id: 'Workspaces' }),
      to: generateLinkTo('companies')
    },
    {
      name: `${intl.formatMessage({ id: 'Connections' })}`,
      to: generateLinkTo('connections')
    }
  ]

  const propsCreateConnection = idCompany
    ? {
        isDisabled: checkPermissionToManageTokens.some(p => !p.canUse),
        'data-for': checkPermissionToManageTokens.some(p => !p.canUse) ? 'tip-global-permission' : undefined,
        'data-tip': checkPermissionToManageTokens.some(p => !p.canUse) ? JSON.stringify(checkPermissionToManageTokens) : undefined
      }
    : (!isLoadingUser && dataUser?.isNewerUserRaw ? {
        disabled: true,
        isDisabled: true,
        'data-for': 'tip-global-permission',
        'data-tip': intl.formatMessage({ id: 'Você precisa criar um workspace para começar a adicionar conexões.' })
      } : {})

  const CreateConnectionButton = () => (
    <Button onClick={() => handleStartCreation()} {...propsCreateConnection}>
      <PlusCircle size={24} />
      <span className='p0 m-0 d-none d-lg-block'>
        {intl.formatMessage({ id: 'Adicionar conexão' })}
      </span>
    </Button>
  )

  const noFoundSearch = !isLoading && searchValue?.length > 0 && data.length === 0
  const noConnectionsYet = !isLoading && searchValue?.length === 0 && data.length === 0
  const hasConnections = !isLoading && data.length > 0

  return (
    <TemplateDefault
      isPocket={isPocket}
      isFullContainer={isFullContainer}
      leftHeader={ !isPocket && <Breadcrumbs crumbs={crumbs} />}
      rightHeader={ !isPocket && <CreateConnectionButton />}
    >
      <Helmet>
        <title>{intl.formatMessage({ id: 'Conexões' })}</title>
      </Helmet>
      <div className='d-flex align-items-center'>
        <Col className='px-0' xs={6}>
          <SearchFilterS search={searchValue} onChangeSearch={handleChangeSearch} debounceTime={500} />
        </Col>
        <div className='text-center mx-3'>
          {iconToSearchAdvanced}
        </div>
        <div>
          {isPocket && <CreateConnectionButton />}
        </div>
      </div>
      {topExtra}
      <TagsS>
        {Array.from(idsCompanyFilters).map(id => (
          <Tag key={id} onClose={() => handleOnCloseCompany(id)}>
            <IconSVG icon='company' />
            <Text>{companies.find(c => c.id === id)?.name}</Text>
          </Tag>
        ))}
        {Array.from(plataformConnectionFilters).map(platform => (
          <Tag key={platform} onClose={() => handleOnCloseConnections(platform)}>
            <IconSVG icon={platform as IconNameI} />
            <Text>{platform}</Text>
          </Tag>
        ))}
      </TagsS>
      <Row>
        {isLoading && (<Col><ListConnectionLoader/></Col>)}
        {noFoundSearch && (<NotFoundSearch button={{ label: intl.formatMessage({ id: 'view.all.connections' }), onClick: () => handleChangeSearch('') }}/>)}
        {noConnectionsYet && (<NoConnectionsYet />)}
        {hasConnections && generateListCardConnection(data)}
      </Row>
      {contentCreateConnection}
      {contentEditConnection}
    </TemplateDefault>
  )
}

export default Connections
