import { Eye, EyeClosed } from '@phosphor-icons/react'
import React, { useState } from 'react'

import Input from '.'

function InputPassword ({ value, onChange, className, placeholder, autocomplete }: any) {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <div style={{ position: 'relative' }}>
      <Input
        className={`pe-6 ${className}`}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        autocomplete={autocomplete}
        onChange={onChange}
      />
      <div
        className="addon"
        onClick={() => setShowPassword(!showPassword)}
        style={{
          position: 'absolute',
          right: '20px',
          top: 'calc(50% - 14px)',
          cursor: 'pointer'
        }}
      >
        {showPassword ? (
          <Eye size={24} color="#DDDDDC" />
        ) : (
          <EyeClosed size={24} color="#DDDDDC" />
        )}
      </div>
    </div>
  )
}

export default InputPassword
