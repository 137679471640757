import { Desktop, PlusCircle } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import permissions from 'Constants/permissions.constants'
import { Button, Col } from 'DesignSystem'

import useFirstSetupFlowManager from 'Components/FirstSetupFlow'
import NotFoundSearch from 'Components/NotFoundSearch/NotFoundSearch.component'
import SearchFilter from 'Components/SearchFilter'
import TemplateDefault from 'Components/TemplateDefault'
import WarningPaymentTrial from 'Components/WarningPaymentTrial'

import useCheckPermission from 'Hooks/API/useCheckPermission.hook'
import useCompanies from 'Hooks/API/useCompanies.hook'
import useIsFirstAccess from 'Hooks/useIsFirstAccess.hook'
import useQuery from 'Hooks/useQuery.hook'

import Filter from './Components/Filter'
import ListCompanyLoader from './Components/ListCompanyLoader.component'
import ListWorkspaces from './Components/ListWorkspaces.component'

export default function Companies () {
  const intl = useIntl()
  const query = useQuery()
  const history = useHistory()
  const { isFirstAccess } = useIsFirstAccess()
  const searchValue = query.get('search') || ''

  const orderByValue = query.get('orderBy')
  const { data: companies, isLoading: isLoadingCompanies, mutate } = useCompanies({
    search: searchValue,
    orderBy: orderByValue
  })

  const { data: checkPermissionToCreateCompany, isLoading } = useCheckPermission([permissions.CREATE_COMPANY])
  const canCreateNewWorkspace = !(!isLoading && checkPermissionToCreateCompany.some(p => !p.canUse))

  const { start, content: firstSetupFlow } = useFirstSetupFlowManager({ isFirstTime: isFirstAccess, onClose: mutate })

  const setQueryParams = (
    search: string | null = searchValue,
    orderBy: string | null = orderByValue
  ) => {
    search ? query.set('search', search) : query.delete('search')
    orderBy ? query.set('orderBy', orderBy) : query.delete('orderBy')

    history.replace({
      search: `${query.toString()}`
    })
  }

  const handleChangeSearch = (value: string) => {
    setQueryParams(value)
  }

  const handleChangeOrderBy = (value: string) => {
    setQueryParams(undefined, value)
  }

  useEffect(() => {
    if (isFirstAccess) {
      start()
    }
  }, [isFirstAccess])

  const handleCreateWorkspace = () => {
    start()
  }

  const propsCreateWorkspace = !canCreateNewWorkspace
    ? {
        isDisabled: !canCreateNewWorkspace,
        'data-for': !canCreateNewWorkspace ? 'tip-global-permission' : undefined,
        'data-tip': !canCreateNewWorkspace ? intl.formatMessage({ id: 'Número máximo de workspaces atingido. Em caso de dúvidas, acione nosso suporte.' }) : undefined
      }
    : {}

  const CreateCompanyButton = () => (
    <Button onClick={handleCreateWorkspace} {...propsCreateWorkspace}>
      <PlusCircle size={24} />
      <span className='p0 m-0 d-none d-lg-block'>
        {intl.formatMessage({ id: 'Criar workspace' })}
      </span>
    </Button>
  )

  const NoConnectionsYet = () => (
    <div className='text-center w-100'>
      <h2 className='fw-bold'>{intl.formatMessage({ id: 'Você não possui Workspaces configurados.' })}</h2>
      <div className='mt-3'>
        <Desktop weight='fill' size='15rem' />
      </div>
      <div className='mt-3'>
        <CreateCompanyButton />
      </div>
    </div>
  )

  const noFoundSearch = !isLoadingCompanies && searchValue?.length > 0 && companies.length === 0
  const noConnectionsYet = !isLoadingCompanies && searchValue?.length === 0 && companies.length === 0
  const hasConnections = !isLoadingCompanies && companies.length > 0

  return (
    <TemplateDefault
    leftHeader={<h4>{intl.formatMessage({ id: 'Seus Workspaces' })}</h4>}
    rightHeader={<CreateCompanyButton/>}
    >
      <Helmet>
        <title>{intl.formatMessage({ id: 'Seus Workspaces' })}</title>
      </Helmet>
      <div className='d-flex align-items-center'>
        <Col className='px-0' xs={11} lg={6}>
          <SearchFilter
            search={searchValue}
            onChangeSearch={handleChangeSearch}
            collectOnMobile
            debounceTime={500}
          />
        </Col>
        <div className='mx-3'>
          <Filter
            size="normal"
            orderBy={orderByValue}
            onChangeOrderBy={handleChangeOrderBy}
            />
        </div>
      </div>
      <div className='mt-5'>
        <WarningPaymentTrial />
        {isLoading && (<Col><ListCompanyLoader/></Col>)}
        {noFoundSearch && (<NotFoundSearch button={{ label: intl.formatMessage({ id: 'view.all.workspaces' }), onClick: () => handleChangeSearch('') }} />)}
        {noConnectionsYet && (<NoConnectionsYet />)}
        {hasConnections && <ListWorkspaces canCreateWorkspace={canCreateNewWorkspace} onAfterCreateCompany={mutate} companies={companies} />}
        {firstSetupFlow}
      </div>
    </TemplateDefault>
  )
}
