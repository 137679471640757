import React from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/macro'

import ButtonFederatedLogin from 'Components/ButtonFederatedLogin'
import TextDiviser from 'Components/TextDiviser'

import { RootS, WrapperInnerS } from './ListButtonFederatedLogin.styled'

interface ListButtonFederatedLoginPropsI {
  isSignUp?: boolean;
  isMigration?: boolean;
  className?: string;
  hasOr?: boolean;
  messageOrOnStart?: boolean;
  color?: string;
  slug?: string | null;
  isAdmin?: boolean;
  only?: string | null;
  invite?: string;
  showMinimal?: boolean;
  pricePreChosen?: string | null;
}

const ListButtonFederatedLogin = (props: ListButtonFederatedLoginPropsI) => {
  const {
    isSignUp = false,
    isMigration = false,
    className,
    slug,
    color,
    hasOr,
    isAdmin = false,
    invite,
    messageOrOnStart = true,
    showMinimal = false,
    only
  } = props
  const intl = useIntl()
  const contentOr = hasOr ? (
    <TextDiviser>{intl.formatMessage({ id: 'ou' })}</TextDiviser>
  ) : null

  return (
    <RootS className={className}>
      {messageOrOnStart ? contentOr : null}
      <WrapperInnerS showMinimal={showMinimal}>
        {!only || only === 'google' ? <ButtonFederatedLogin className={showMinimal ? 'px-4 py-0' : 'w-100 mt-2'} showMinimal={showMinimal} isSignUp={isSignUp} isMigration={isMigration} platform='google' slug={slug} isAdmin={isAdmin} color={color} invite={invite} /> : null}
        {!only || only === 'microsoft' ? <ButtonFederatedLogin className={showMinimal ? 'px-4 py-0' : 'w-100 mt-2'} showMinimal={showMinimal} isSignUp={isSignUp} isMigration={isMigration} platform='microsoft' slug={slug} isAdmin={isAdmin} color={color} invite={invite} /> : null}
        {!only || only === 'facebook' ? <ButtonFederatedLogin className={showMinimal ? 'px-4 py-0' : 'w-100 mt-2'} showMinimal={showMinimal} isSignUp={isSignUp} isMigration={isMigration} platform='facebook' slug={slug} isAdmin={isAdmin} color={color} invite={invite} /> : null}
        {!only || only === 'amazon' ? <ButtonFederatedLogin className={showMinimal ? 'px-4 py-0' : 'w-100 mt-2'} showMinimal={showMinimal} isSignUp={isSignUp} isMigration={isMigration} platform='amazon' slug={slug} isAdmin={isAdmin} color={color} invite={invite} /> : null}
      </WrapperInnerS>
      {!messageOrOnStart ? contentOr : null}
    </RootS>
  )
}

export default styled(ListButtonFederatedLogin)``
