import { X } from '@phosphor-icons/react'
import React, { useState, useEffect, useRef } from 'react'

import styled from 'styled-components'

const TooltipContainer = styled.div<{
  show: boolean;
  position: 'right' | 'left';
}>`
  position: absolute;
  max-width: 600px;
  width: 400px;
  background-color: #fcfcfc;
  border: 1px solid #c6c6c5;
  color: #000;
  text-align: left;
  padding: 2.5rem;
  border-radius: ${({ position }) =>
    position === 'right' ? '0px 24px 24px 24px' : '24px 0px 24px 24px'};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 0.3s;
  ${({ position }) => (position === 'right' ? 'left: 100%;' : 'right: 100%;')};
  margin-top: 5px;

  @media (max-width: 768px) {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 95vw;
    padding: 1.5rem;
    border-radius: 24px;
  }
`

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;

  span {
    cursor: pointer;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  background: none;
  border: none;
  color: #000;
  font-size: 14px;
  cursor: pointer;
`

type TooltipProps = {
  content: React.ReactNode;
  children: React.ReactNode;
  position?: 'right' | 'left';
};

const Tooltip: React.FC<TooltipProps> = ({ content, children, position = 'right' }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const ref = useRef<any>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowTooltip(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <TooltipWrapper ref={ref} className="tooltip-wrapper">
      <span onClick={() => setShowTooltip(!showTooltip)}>
        {children}
      </span>
      <TooltipContainer show={showTooltip} position={position}>
        <CloseButton onClick={() => setShowTooltip(false)}>
          <X size={'1.5rem'} />
        </CloseButton>
        {content}
      </TooltipContainer>
    </TooltipWrapper>
  )
}

export default Tooltip
