import React from 'react'

import { Modal } from 'DesignSystem'

import useContentManager from 'Hooks/useContentManager.hook'

import StepsVisual from '../StepsVisual'
import SelectPlatform from './Steps/SelectPlatform'

export interface DataCompanyCreateContentManagerI {
  companies?: string[]
  platform?: string
  setEnableNext?: () => null
}

interface ParamsI {
  idCompany?: string,
  onClose?: () => void,
  isFirstTime?: boolean
}

const useCreateConnectionFlowManager = (params: ParamsI = {}) => {
  const { onClose } = params

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const steps = [
    SelectPlatform
  ]

  const { start, content } = useContentManager<DataCompanyCreateContentManagerI>(steps, {
    footer: (currentDialog) => <StepsVisual steps={3} current={currentDialog} />,
    dialog: Modal,
    isFit: true,
    onClose: handleOnClose,
    initialData: {
    }
  })

  return {
    start,
    content
  }
}

export default useCreateConnectionFlowManager
